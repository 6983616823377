<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex">
            <vs-button v-if="!selectable && $can('create_kontrak')" color="primary" type="border" icon-pack="feather" icon="icon-plus" class="px-3 mr-3" :to="{ name: 'marketing.kontrak.add' }">Tambah</vs-button>
            <vs-button v-if="!selectable" color="primary" :type="isEmptyFilter ? 'border' : 'filled'" icon-pack="feather" icon="icon-filter" class="px-3" @click="modalFilterActive = true">Filter</vs-button>
          </div>
        </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap" v-if="!selectable">Aksi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="booking_asal">No. Kontrak</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tgl">Tgl Kontrak</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status_kontrak">Status</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="booking_asal">Booking Ref.</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="no_unit">No.Unit</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_proyek">Nama Proyek</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="kode_kategori">Properti Kategori</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="properti_tipe">Properti Tipe</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="kode_cluster">Cluster</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_staf">Nama Staf</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_customer">Nama Pemilik</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_customer">Nama Pemesan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="cara_bayar">Rencana Bayar</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="keterangan_booking">Keterangan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="username">Created By</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Created At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td v-if="!selectable">
              <div class="flex space-x-2">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                  <vs-dropdown-menu>
                    <vs-dropdown-item class="p-1" @click="printPpjb(item.id)">Print PPJB</vs-dropdown-item>
                    <vs-dropdown-item class="p-1" @click="printStc(item.id)" :disabled="!item.id_stc">Print STC</vs-dropdown-item>
                    <vs-dropdown-item class="p-1" v-if="$can('delete_kontrak')" :disabled="item.status_kontrak === 'CLOSE' || !!item.id_jurnal" @click="confirmDelete(item.id)"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="py-2 px-3" type="border" icon-pack="feather" icon="icon-eye" :to="{ name: 'marketing.kontrak.detail', params: { idKontrak: item.id } }"/>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.no_kontrak">{{ item.no_kontrak }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.tgl">{{ item.tgl }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.status_kontrak"><span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs leading-none text-white rounded-full" :class="['bg-' + item.status_kontrak_color]">{{ item.status_kontrak }}</span></vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.no_booking_asal">{{ item.no_booking_asal }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.no_unit">{{ item.no_unit }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_proyek">{{ item.nama_proyek }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.properti_kategori">{{ item.nama_properti_kategori }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.properti_tipe">{{ item.nama_properti_tipe }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_cluster">{{ item.nama_cluster }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_staf">{{ item.nama_staf }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_customer">{{ item.nama_customer }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_pemesan">{{ item.nama_pemesan }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_cara_bayar">{{ item.nama_cara_bayar }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.keterangan_kontrak">{{ item.keterangan_kontrak }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.username">{{ item.username }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.created_at">{{ item.created_at }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>

    </vx-card>

    <!--modals-->
    <KontrakFilter v-if="!selectable" :isActive.sync="modalFilterActive" @filter="onFilter"/>
  </div>
</template>

<script>
import KontrakRepository from '@/repositories/marketing/kontrak/kontrak-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import _ from 'lodash'
import printoutMixin from '@/utilities/mixins/printout-mixin'

export default {
  name: 'Customer',
  props: {
    selectable: { default: false, type: Boolean },
    externalFilter: { default: null, type: Object }
  },
  components: {
    PagingLimit,
    KontrakFilter: () => import('@/views/pages/marketing/kontrak/KontrakFilter'),
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv')
  },
  mixins: [printoutMixin],
  watch: {
    externalFilter (newVal, oldVal) {
      if (newVal && !_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  data () {
    return {
      modalFilterActive: false,
      filter: {},
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    },
    isEmptyFilter () {
      return _.isEmpty(this.filter)
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      params.filter = JSON.stringify({
        only_authorized_user: 1,
        ...this.filter
      })

      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      KontrakRepository.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    delete (id) {
      KontrakRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    onFilter (filterData) {
      this.filter = filterData
      this.getData()
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    printPpjb (id) {
      this.showPrintOutFile('PPJB', { id: id })
    },

    printStc (id) {
      this.showPrintOutFile('BAST_STC', { id: id })
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
